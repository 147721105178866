import { toast } from "react-toastify";
import axios from "axios";

import Instance from "../../utils/Instance";
import {
  CANCEL_LOADING,
  CLEAR_SEARCH,
  DASHBOARD_SEARCH,
  GET_ACTIVE_PUSH_INFO,
  GET_POPULAR_POST,
  LANDING_SEARCH,
  SET_LOADING,
} from "../ActionTypes";

export const dashboard = () => (dispatch) => {
  axios
    .get(`/dashboard`)
    .then((resp) => {
      dispatch({
        type: GET_POPULAR_POST,
        payload: resp.data.data,
      });
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getActivePushInfo = () => async (dispatch) => {
  axios
    .get(`/push_info/active`, { params: { web: true } })
    .then((resp) => {
      dispatch({
        type: GET_ACTIVE_PUSH_INFO,
        payload: resp.data.data,
      });
    })
    .catch((err) => {
      console.log(err.message);
    });
};

let cancelToken;
export const dashboardSearch = (search, dSize, page) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
  });
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  try {
    let result = await Instance.get(`/dashboard/search?searchText=${search}&pageSize=${dSize}&page=${page}`, {
      cancelToken: cancelToken.token,
    });
    if (result.status === 200) {
      dispatch({
        type: DASHBOARD_SEARCH,
        payload: result.data.data,
      });

      // result.data.data.allPost.length === 0 &&
      //   toast.dark("No serch Data Found", {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
    } else {
      dispatch({
        type: CANCEL_LOADING,
      });
    }
  } catch (err) {
    dispatch({
      type: CANCEL_LOADING,
    });
    console.log(err.message);
  }
};

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
  payload: "",
});

export const landingSearch = (data) => (dispatch) => {
  return axios
    .post(`/dashboard/postSearch`, data)
    .then((resp) => {
      dispatch({
        type: LANDING_SEARCH,
        payload: resp.data.data,
      });

      resp.data.data.allPost.length === 0 &&
        toast.dark("No serch Data Found", {
          position: toast.POSITION.TOP_CENTER,
        });
    })

    .catch((err) => {
      console.log(err.message);
    });
};
