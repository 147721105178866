import React from "react";

import { FooterWrapper } from "./Footerstyle";

function FooterComp() {
  return (
    <FooterWrapper>
      {/* <div className="main-container pt-16 pb-8 lg:px-16 md:px-12 sm:px-4"> */}
      <div className="main-container sm:px-2 pt-16 sm:pt-8 sm:pb-4 pb-8 ">
        <div className=" w-3/4 sm:w-full m-auto  sm:text-sm ">
          <div className="grid gap-1 grid-cols-3 sm:grid-cols-1">
            <a
              href="https://construcshare.com"
              className="flex items-center flex-col sm:flex-row sm:gap-3 sm:items-center sm:justify-center sm:hidden"
            >
              <img src="/icons/logo-red.svg" alt="company-logo" className="w-12" />
              <div className="logo-title mt-2">
                <span>
                  construc<b>share</b>
                </span>
              </div>
            </a>
            <div className="learnmore text-left sm:text-center sm:mt-3">
              <div className="learnmore-title mb-5 sm:mb-2 sm:hidden">Learn More</div>
              <div className="learnmore-links">
                <ul className="sm:flex sm:flex-row sm:gap-2 sm:items-center sm:justify-center">
                  <li className="sm:w-auto sm:flex-shrink-0 sm:text-right">
                    <a href="https://construcshare.com/terms-and-conditions/">Terms & Conditions</a>
                  </li>
                  <img src="/icons/logo-red.svg" alt="company-logo" className="w-8 hidden sm:inline" />
                  {/* <span className="w-4 hidden sm:inline">&#x25EF;</span> */}
                  <li className="sm:w-auto sm:flex-shrink-0 sm:text-left">
                    <a href="https://construcshare.com/privacy-policy/">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="connect text-left sm:text-center justify-self-center sm:mt-3">
              <div className="connect-title mb-5 sm:mb-2">Contact Us</div>
              <div className="connect-links">
                <ul>
                  <li>
                    <a href="mailto:helpdesk@construcshare.com">helpdesk@construcshare.com</a>
                  </li>
                  <li>
                    <a href="tel:66840942">+65 6684 0942</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="grid grid-cols-3 sm:grid-cols-1">
          <div className="logo-wrapper md:w-full">
            <div className="logo sm:w-full">
              <img src="/icons/logo-red.svg" alt="" />
              <div className="logo-title">
                <span>
                  construc<b>share</b>
                </span>
              </div>
            </div>
          </div>

          <div className="learnmore  sm:w-full">
            <div className="learnmore-title">Learn More</div>
            <div className="learnmore-links">
              <ul>
                <li>
                  <a href="https://construcshare.com/terms-and-conditions/">Terms and Conditions</a>
                </li>
                <li>
                  <a href="https://construcshare.com/privacy-policy/">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="connect sm:w-full">
            <div className="connect-title">Connect With Us</div>
            <div className="connect-links">
              <ul>
                <li>
                  <a href="mailto:admin@construcshare.com">admin@construcshare.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
          <div className="footnote text-center sm:mt-8 mt-16">
            <div className="">&copy; 2022 construcshare ® All rights reserved. Trade Mark No. 40202130183T</div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
}

export default FooterComp;
