export const NumberFormat = (number, point = 2) => {
  if (isNaN(number)) return 0;
  let formattedNumber = parseInt(number)
    ?.toFixed(point)
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedNumber;
};

export const formatChat = (chat) => {
  let objChat = {
    chat: chat,
  };

  if (chat?.post_id?.category !== "proServices") {
    if (chat?.order_id) {
      objChat.orderDetail = {
        ...chat?.order_id,
        oid: chat?.order_id?.oid,
        orderId: chat?.order_id?._id,
        company: chat?.order_id?.user?.companyName,
        orderDate: chat?.order_id?.createdAt,
        deliveredDate: chat?.order_id?.date,
        total: chat?.order_id?.orderValue,
        quantity: chat?.order_id?.quantity,
        bankCharges: chat?.order_id?.bankCharges,
        csCharges: chat?.order_id?.csCharges,
        surCharges: chat?.order_id?.surCharges,
        orderRating: chat?.order_id?.orderRating,
        status: chat?.order_id?.status,
        buyer: chat?.order_id?.buyer,
        seller: chat?.order_id?.seller,
      };

      if (chat?.post_id?.category === "lease") {
        objChat.orderDetail["period"] = chat?.order_id?.date;
        let timeDiff = new Date(chat.order_id?.date?.to) - new Date(chat.order_id?.date?.from);
        let days = timeDiff / (1000 * 3600 * 24) + 1;
        objChat.orderDetail["duration"] = days;
      } else {
        objChat.orderDetail["period"] = chat.order_id?.createdAt;
      }
    }
  }
  return objChat;
};
