import {
  BOOK_ORDER,
  CANCEL_BOOK_ORDER_MODAL,
  CANCEL_DISPUTE,
  CANCEL_LOADING,
  CREATE_OFFER,
  DISPUTE_LOADING,
  DISPUTE_LOADING_CANCEL,
  GET_MY_ORDER,
  GET_ORDER_DETAILS,
  GET_SINGLE_DISPUTE,
  GET_UNSUCCESSFUL_ORDER,
  SET_LOADING,
  UPDATE_DISPUTE,
  UPDATE_OFFER,
} from "../ActionTypes";

const intialState = {
  successorder: false,
  successOffer: false,
  ids: "",
  loader: false,
  myOrders: [],
  orderData: [],
  unsuccessfulOrders: [],
  offer: null,
  updateData: [],
  cancelformData: {},
  orderDetails: null,
};
export const OrderReducer = (state = intialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loader: true,
      };
    case CANCEL_LOADING:
      return {
        ...state,
        loader: false,
      };
    case DISPUTE_LOADING:
      return {
        ...state,
        loader: true,
      };
    case DISPUTE_LOADING_CANCEL:
      return {
        ...state,
        loader: false,
      };
    case BOOK_ORDER:
      return {
        ...state,
        successorder: true,
        ids: action.payload._id,
        orderData: action.payload,
        myOrders: [...state.myOrders, action.payload],
        loader: false,
      };
    case CREATE_OFFER: {
      if (action.payload) {
        state.offer = action.payload;
        state.successOffer = true;
        state.loader = false;
      }
      return state;
    }
    case GET_ORDER_DETAILS: {
      return {
        ...state,
        orderDetails: action.payload,
      };
    }
    case UPDATE_OFFER:
      return {
        ...state,
        updateData: action.payload,
      };
    case GET_UNSUCCESSFUL_ORDER: {
      return {
        ...state,
        unsuccessfulOrders: action.payload.orders,
      };
    }
    case GET_MY_ORDER:
      return {
        ...state,
        myOrders: action.payload.myOrders,
        loader: false,
      };
    case UPDATE_DISPUTE:
      return {
        ...state,
        myOrders: state.myOrders.map((dispute) => {
          if (dispute?.orderId === action.payload.order._id) {
            return Object.assign({}, dispute, {
              status: action.payload.order.status,
            });
          }
          return dispute;
        }),
        loader: false,
      };
    case CANCEL_DISPUTE:
      return {
        ...state,
        loader: false,
      };
    case GET_SINGLE_DISPUTE:
      return {
        ...state,
        cancelformData: action.payload.dispute,
        loader: false,
      };

    case CANCEL_BOOK_ORDER_MODAL:
      return {
        ...state,
        successorder: false,
        successOffer: false,
        loader: false,
      };

    default:
      return state;
  }
};
