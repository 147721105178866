import styled from "styled-components";

export const HeaderWrapper = styled.div`
  .container-head {
    padding: 0.6rem 1rem 0.6rem 1rem;
    display: flex;
    justify-content: space-between;

    /* background: ${(props) => props.props.background || "#DA0022"}; */
    align-items: center;

    //logo wrapper start------------------------------------------------
    .logo-wrapper {
      display: flex;
      align-items: center;

      svg {
        fill: #fff;
      }

      .title {
        color: white;
        margin-left: 1rem;
        letter-spacing: 0.2rem;
        font-size: 1.2rem;
      }
    }

    //feature wrapper start--------------------------------------------------
    .feature-wrapper {
      /* justify-content: space-around; */
      gap: 16px;
      width: auto;
      align-items: center;

      .vl {
        border-left: 2px solid white;
        height: 3rem;
      }

      .input {
        /* flex-basis: 20rem;
        display: flex;
        flex-shrink: 1; */
        input {
          //height: 2.2rem;
        }
      }

      .bell-envelop {
        margin-left: 1rem;
      }

      .bell-icon,
      .envelop-icon {
        background: white;
        // display: flex;
        align-items: center;
        padding: 0.5rem 0.5rem;

        /* padding: 0.4rem 0.5rem 0.3rem 0.5rem; */
        border-radius: 0.5rem;
        cursor: pointer;
        svg {
          fill: #8b0000;
          /* height:1.5em; */
        }
      }

      .profile {
        cursor: pointer;
        display: flex;
        /* align-items: center; */
        gap: 7px;
        img {
          border-radius: 0.3rem;
          width: 2.4rem;
          height: 2.4rem;
          align-self: center;
        }
      }

      .profile-info {
        color: white;
        font-size: 0.8rem;
        align-self: center;
        /* margin-left: -1rem; */
        cursor: pointer;
        &-name {
          font-weight: bold;
          /* white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%; */
        }
      }
      .arrow-btn {
        // margin-top: 1.3rem;
        color: white;
        svg {
          stroke-width: 1;
          cursor: pointer;
          width: 0.7em;
        }
      }
    }

    //feature wrapper ends-----------------------------------------------------

    // feature button starts----------------------------------------------------

    .feature-buttons {
      // display: flex;
      // align-items: center;
      // justify-content: space-around;
      // width: 30rem;

      .feature-link {
        color: white;
        font-weight: 600;
        cursor: pointer;
      }
    }
    // feature button ends------------------------------------------------------
  }
  .dropdown {
    position: relative;
    // display: inline-block;
  }
  .dropdown-content {
    //display: none;
    position: absolute;
    right: 0rem;
    margin-top: 0.7rem;
    background-color: #f1f1f1;
    // width: 12vw;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .dropdown-content {
    h5,
    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      cursor: pointer;
    }
  }
  // .dropdown-content a:hover {background-color: #ddd;}

  //.dropdown:hover .dropdown-content {display: block;}

  //.dropdown:hover .profile {background-color: #3e8e41;}
`;
