//user actions
export const LOGOUT_USER = "LOGOUT_USER";
export const USER_LOGIN = "USER_LOGIN";
export const USER_SIGNUP = "USER_SIGNUP";
export const USER_ALREADY_SUCCESSFULLY_REGISTERED = "USER_ALREADY_SUCCESSFULLY_REGISTERED";
export const USER_FORGET_PASSWORD = "USER_FORGET_PASSWORD";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";
export const USER_VERIFICATION = "USER_VERIFICATION";
export const USER_COMPANY = "USER_COMPANY";
export const USER_UPDATE = "USER_UPDATE";
export const USER_TYPE_FORM_TOUCHED = "USER_TYPE_FORM_TOUCHED";
export const USER_FINAL_FORM = "USER_FINAL_FORM";
export const USER_VERFICATION_RESEND_MAIL = "USER_VERIFICATION_RESEND_MAIL";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADING_CANCEL = "USER_LOADING_CANCEL";
export const GET_INTEREST = "GET_INTEREST";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const USER_VERIFY_OTP = "USER_VERIFY_OTP";
export const USER_RESET = "USER_RESET";
export const USER_STEP_INCREASE = "USER_STEP_INCREASE";
export const RESET_COMPANY = "RESET_COMPANY";
export const GET_UEN_COUNT = "GET_UEN_COUNT";
export const GET_UEN_TOKEN = "GET_UEN_TOKEN";
export const UPDATE_USER_TOKEN = "UPDATE_USER_TOKEN";
export const IS_COMPLETE_PROFILE = "IS_COMPLETE_PROFILE";
export const SINGPASS_ACCESS_TOKEN = "SINGPASS_ACCESS_TOKEN";
export const SET_USER_SIGNUP_TOKEN = "SET_USER_SIGNUP_TOKEN";

// Tazapay
export const UPDATE_TAZAPAY_STATUSES = "UPDATE_TAZAPAY_STATUSES";

//loading
export const SET_LOADING = "SET_LOADING";
export const CANCEL_LOADING = "CANCEL_LOADING";

//post action

export const GET_POST = "GET_POST";
export const CREATE_POST = "CREATE_POST";
export const DELETE_POST = "DELETE_POST";
export const PREVIEW_POST = "PREVIEW_POST";
export const GET_USER_POST = "GET_USER_POST";
export const GET_DRAFT_POST = "GET_DRAFT_POST";
export const POST_FILTER = "POST_FILTER";
export const GET_SIMILAR_POST = "GET_SIMILAR_POST";
export const POST_LOADING = "POST_LOADING";
export const POST_LOADING_CANCEL = "POST_LOADING_CANCEL";
export const CANCEL_POST_MODAL = "CANCEL_POST_MODAL";
export const GET_CATEGORY = "GET_CATEGORY";
export const UPDATE_POST = "UPDATE_POST";
export const POST_DETAILS = "POST_DETAILS";
export const CLEAR_POST_DETAIL = "CLEAR_POST_DETAIL";
export const RESET_POST = "RESET_POST";
export const HAS_MORE_POST = "HAS_MORE_POST";

//form action

export const GET_TOUCHIN_FORM = "GET_TOUCHIN_FORM";
export const CANCEL_TOUCHIN_FORM = "CANCEL_TOUCHIN_FORM";
export const GET_ENGAGE_FORM = "GET_ENGAGE_FORM";
export const CANCEL_ENGAGE_FORM = "CANCEL_ENGAGE_FORM";

//dashboard action

export const GET_POPULAR_POST = "GET_POPULAR_POST";
export const GET_ACTIVE_PUSH_INFO = "GET_ACTIVE_PUSH_INFO";
export const DASHBOARD_SEARCH = "DASHBOARD_SEARCH";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const GET_ALL_CONVERSATION = "GET_ALL_CONVERSATION";
export const RESET_CHAT_COUNT = "RESET_CHAT_COUNT";
//plan action

export const GET_PLAN = "GET_PLAN";
export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";

//landing page post action

export const GET_LANDING_POST = "GET_LANDING_POST";
export const LANDING_SEARCH = "LANDING_SEARCH";

//book order book-service

export const BOOK_ORDER = "BOOK_ORDER";
export const CREATE_OFFER = "CREATE_OFFER";
export const CANCEL_BOOK_ORDER_MODAL = "CANCEL_BOOK_ORDER_MODAL";

//cockpit

export const GET_MY_COCKPIT = "GET_MY_COCKPIT";
export const GET_COWORKER_COCKPIT = "GET_COWORKER_COCKPIT";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const ORDER_PAYMENT_STATUS = "ORDER_PAYMENT_STATUS";
export const COCKPIT_LOADING = "COCKPIT_LOADING";
export const COCKPIT_LOADING_CANCEL = "COCKPIT_LOADING_CANCEL";
export const INVOICE_PDF = "INVOICE_PDF";

// ORDER

export const GET_MY_ORDER = "GET_MY_ORDER";
export const DISPUTE_LOADING = "DISPUTE_LOADING";
export const DISPUTE_LOADING_CANCEL = "DISPUTE_LOADING_CANCEL";

export const GET_UNSUCCESSFUL_ORDER = "GET_UNSUCCESSFUL_ORDER";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";

export const UPDATE_DISPUTE = "UPDATE_DISPUTE";

export const CANCEL_DISPUTE = "CANCEL_DISPUTE";

export const GET_SINGLE_DISPUTE = "GET_SINGLE_DISPUTE";

//review
export const CREATE_REVIEW = "CREATE_REVIEW";
export const CANCEL_CREATE_REVIEW_MODAL = "CANCEL_CREATE_REVIEW_MODAL";
export const GET_REVIEW = "GET_REVIEW";
export const REVIEW_PRINT = "REVIEW_PRINT";
export const GET_REVIEW_DETAIL = "GET_REVIEW_DETAIL";
export const REVIEW_LOADING = "REVIEW_LOADING";
export const REVIEW_LOADING_CANCEL = "REVIEW_LOADING_CANCEL";

//performance
export const PERFORMANCE_SEARCH = "PERFORMANCE_SEARCH";
export const PERFORMANCE_SEARCH2 = "PERFORMANCE_SEARCH2";

export const GET_PERFORMANCE_DETAIL = "GET_PERFORMANCE_DETAIL";
export const PERFORMANCE_LOADING = "PERFORMANCE_LOADING";
export const PERFORMANCE_LOADING_CANCEL = "PERFORMANCE_LOADING_CANCEL";
export const PERFORMANCE_PDF = "PERFORMANCE_PDF";

//chat

export const GET_CONVERSATION = "GET_CONVERSATION";
export const OFFER_AGREE = "OFFER_AGREE";
export const CANCEL_AGREE_MODAL = "CANCEL_AGREE_MODAL";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const GET_ADMIN_CONVERSATION = "GET_ADMIN_CONVERSATION";
export const CREATE_NORMAL_CONVERSATION = "CREATE_NORMAL_CONVERSATION";

//notification

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION_DELETE = "NOTIFICATION_DELETE";
export const NOTIFICATION_MARK_SEEN = "NOTIFICATION_MARK_SEEN";
export const NOTIFICATION_MARK_ALL_SEEN = "NOTIFICATION_MARK_ALL_SEEN";

//payment

export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const PAYMENT_LOADING = "PAYMENT_LOADING";
export const CANCEL_PAYMENT_LOADING = "CANCEL_PAYMENT_LOADING";

// Coworkers
export const GET_COWORKER = "GET_COWORKER";

// Following
export const FOLLOWING_LIST = "FOLLOWING_LIST";
export const FOLLOWING_ADD = "FOLLOWING_ADD";
export const FOLLOWING_DELETE = "FOLLOWING_DELETE";
export const FOLLOWING_LOADING_START = "FOLLOWING_LOADING_START";
export const FOLLOWING_LOADING_END = "FOLLOWING_LOADING_END";
export const FOLLOWING_ENABLE_POST_NOTIFY = "FOLLOWING_ENABLE_POST_NOTIFY";
export const FOLLOWING_DISABLE_POST_NOITY = "FOLLOWING_DISABLE_POST_NOITY";

//Followers
export const FOLLOWERS_LIST = "FOLLOWERS_LIST";
export const FOLLOWERS_LOADING_START = "FOLLOWING_LOADING_START";
export const FOLLOWERS_LOADING_END = "FOLLOWING_LOADING_END";

// Buyer Request
export const CREATE_BUYER_REQUEST = "CREATE_BUYER_REQUEST";
export const ALL_BUYER_REQUEST = "ALL_BUYER_REQUEST";
export const MY_BUYER_REQUEST = "MY_BUYER_REQUEST";
export const SINGLE_BUYER_REQUEST = "SINGLE_BUYER_REQUEST";
export const DELETE_BUYER_REQUEST = "DELETE_BUYER_REQUEST";
