import { OrderStatus } from "../../utils/constants";
import {
  CANCEL_AGREE_MODAL,
  CREATE_NORMAL_CONVERSATION,
  GET_ADMIN_CONVERSATION,
  GET_ALL_CONVERSATION,
  GET_CONVERSATION,
  OFFER_AGREE,
  RESET_CHAT_COUNT,
  UPDATE_ORDER_STATUS,
} from "../ActionTypes";

const intialState = {
  chatData: null,
  helpDesk: null,
  contacts: [],
  totalUnread: 0,
  successConfirm: false,
  chatLoading: true,
};
export const ChatReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_ALL_CONVERSATION:
      return {
        ...state,
        contacts: action.payload.inbox,
        totalUnread: action.payload.totalUnreadCount,
        chatLoading: false,
      };
    case RESET_CHAT_COUNT: {
      if (action.payload.totalUnread >= 0) {
        const contacts = state.contacts?.map((c) => {
          if (c._id === action.payload.chatId) {
            c.unreadCount = 0;
          }
          return c;
        });

        return {
          ...state,
          contacts,
          totalUnread: action.payload.totalUnread,
        };
      }
      return state;
    }
    case GET_CONVERSATION:
      return {
        ...state,
        chatData: action.payload,
      };
    case GET_ADMIN_CONVERSATION:
      return {
        ...state,
        helpDesk: action.payload,
      };
    case OFFER_AGREE: {
      state.chatData.orderDetail.status = OrderStatus.PENDING_PAYMENT;
      state.chatData.chat.order_id.status = OrderStatus.PENDING_PAYMENT;

      const { conversation_id } = action.payload;

      const contacts = state.contacts?.map((c) => {
        if (c._id === conversation_id) {
          c.status = OrderStatus.PENDING_PAYMENT;
        }
        return c;
      });

      return {
        ...state,
        contacts,
        successConfirm: true,
      };
    }

    case UPDATE_ORDER_STATUS: {
      state.chatData.chat.order_id.status = action.payload;
      state.chatData.orderDetail.status = action.payload;
      return {
        ...state,
      };
    }

    case CREATE_NORMAL_CONVERSATION:
      return {
        ...state,
        chatData: action.payload,
      };

    case CANCEL_AGREE_MODAL:
      return {
        ...state,
        successConfirm: false,
      };

    // case UPDATE_DISPUTE:
    // return {
    //   ...state,
    //   myOrders: state.myOrders.map((dispute,index) => {
    //     if (dispute?.orderId == action.payload.order._id) {
    //       return Object.assign({}, dispute, {
    //         status: action.payload.order.status,
    //       });
    //     }
    //     return dispute;
    //   }),
    //
    // };
    default:
      return state;
  }
};
