import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import Banner from "../components/Banner/Banner";
import FooterComp from "../components/Footer/FooterComp";
import HeaderComp from "../components/Header/HeaderComp";
import SideBarComp from "../components/SideBar/SideBarComp";

export const PrivateLayout = ({ children }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const onClickBurger = () => setOpen((open) => !open);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (open) {
      document.body.classList.add("sidebar-open");
    } else {
      document.body.classList.remove("sidebar-open");
    }
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <div className="">
      {(!user?.firstname || !user?.lastname) && (
        <Banner
          title="Incomplete profile"
          description={
            <div>
              Please complete your profile before doing any transaction{" "}
              <Link className="underline text-slate-400" to="/profile-edit">
                Click here
              </Link>
            </div>
          }
          className="p-2"
        />
      )}

      <HeaderComp mainheader onClickBurger={onClickBurger} />

      <div className=" flex flex-row py-5 overflow-hidden ">
        <SideBarComp
          open={open}
          closeBtn={
            <BsArrowLeftShort
              className={`hidden cursor-pointer  sm:block ml-auto mr-2 sticky top-0 right-0`}
              size={50}
              color="red"
              onClick={onClickBurger}
            />
          }
          // className="w-96 sm:w-64"
        />
        <div className="flex-1 w-full px-7 sm:px-4 overflow-hidden " style={{ minHeight: 500 }}>
          {children}
        </div>
      </div>

      <FooterComp />
    </div>
  );
};

export const AuthLayout = ({ children }) => {
  return (
    <div className="">
      <HeaderComp />

      <div className=" flex flex-row py-5 overflow-hidden">
        <div className="flex-1 w-full py-7 sm:px-4">{children}</div>
      </div>

      <FooterComp />
    </div>
  );
};
