import { toast } from "react-toastify";
import axios from "axios";

import { history } from "../../routes";
import { SingpassKeys } from "../../utils/constants";
import {
  GET_COWORKER,
  GET_INTEREST,
  GET_UEN_COUNT,
  GET_UEN_TOKEN,
  LOGOUT_USER,
  PROFILE_UPDATE,
  RESET_COMPANY,
  SET_USER_SIGNUP_TOKEN,
  SINGPASS_ACCESS_TOKEN,
  UPDATE_TAZAPAY_STATUSES,
  UPDATE_USER_TOKEN,
  USER_ALREADY_SUCCESSFULLY_REGISTERED,
  USER_COMPANY,
  USER_FINAL_FORM,
  USER_LOADING,
  USER_LOADING_CANCEL,
  USER_LOGIN,
  USER_RESET,
  USER_RESET_PASSWORD,
  USER_SIGNUP,
  USER_TYPE_FORM_TOUCHED,
  USER_UPDATE,
  USER_VERFICATION_RESEND_MAIL,
  USER_VERIFICATION,
} from "../ActionTypes";

const userLogin = (result) => (dispatch) => {
  if (
    result.data.status === "success" &&
    result.data?.data?.user?.isEmailVerified &&
    result.data?.data?.user?.uen
    // && result.data?.data?.user?.firstname
    // && result.data?.data?.user?.lastname
  ) {
    dispatch({ type: USER_LOGIN, payload: result.data });
    dispatch(getCoworkers());
  } else {
    dispatch({ type: USER_SIGNUP, payload: result });
    if (!result.data.data.user.isEmailVerified) {
      history.push("/signup/verify-user");
    } else if (!result.data.data.user.uen) {
      dispatch({ type: USER_VERIFICATION });
      history.push("/signup/register-profile");
    } else if (!result.data.data.user.firstname || !result.data.data.user.lastname) {
      dispatch({ type: USER_VERIFICATION });
      history.push("/signup/getstarted");
    }
  }
};

export const login = (data) => async (dispatch) => {
  dispatch({ type: USER_LOADING });

  try {
    let result = await axios.post(`/user/login`, data);
    dispatch(userLogin(result));
    dispatch({ type: USER_LOADING_CANCEL });
  } catch (error) {
    // toast.dark(error?.response?.data?.message, {
    //   position: toast.POSITION.TOP_CENTER,
    // });
    dispatch({ type: USER_LOADING_CANCEL });
    return Promise.reject(error);
  }
};

export const singpassLogin = async () => {
  let authApiUrl = `${SingpassKeys.SINGPASS_URL}/auth?${new URLSearchParams({
    client_id: SingpassKeys.CLIENT_ID,
    scope: "openid",
    response_type: "code",
    redirect_uri: SingpassKeys.REDIRECT_URI,
    nonce: Math.floor(Math.random() * 100000),
    state: Math.floor(Math.random() * 100000),
  })}`;

  window.location = authApiUrl;
};

export const handleSingpassClientAssertion = async () => {
  return axios.get("/singpass/client_assertion");
};

export const handleSingpassToken = (payload) => async (dispatch) => {
  return axios
    .post("/singpass/authenticate", payload)
    .then((res) => {
      if (res.data.token) {
        dispatch(userLogin(res));
      } else if (res.data?.accessToken) {
        dispatch({
          type: SINGPASS_ACCESS_TOKEN,
          payload: res.data?.accessToken,
        });
      } else if (res.data?.data.user) {
        // toast.dark("Registration successful", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: true,
        // });
        // dispatch({
        //   type: USER_UPDATE,
        //   payload: res.data?.data,
        // });
        dispatch({
          type: SET_USER_SIGNUP_TOKEN,
          payload: null,
        });
        // history.push("/login");
        return Promise.resolve(true);
      }
    })
    .catch((err) => {
      console.log("ERROR ==> ", err);
      dispatch({
        type: SINGPASS_ACCESS_TOKEN,
        payload: null,
      });
      return Promise.resolve(false);
      // history.push("/login");
    });
};

export const singpassSendOtp = (payload) => {
  return axios.post("/singpass/send-otp", payload);
};

export const singpassVerifyOtp = (payload) => {
  return axios.post("/singpass/verify-otp", payload);
};

export const singpassLinkUser = (payload) => {
  return axios.post("/singpass/link", payload);
};

export const getCoworkers = () => async (dispatch) => {
  try {
    const res = await axios.get("/user/getCoworkers");
    dispatch({
      type: GET_COWORKER,
      payload: res.data.users,
    });
  } catch (error) {
    console.log(error);
  }
};

export const findUenCount = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_UEN_COUNT, payload: "" });
    const resp = await axios.get(`/user/findUenCount/${data}`);
    if (resp.data.status === "success") {
      dispatch({
        type: USER_COMPANY,
        payload: resp.data?.data?.name,
      });
      return resp.data?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error.message);
    toast.dark(error?.response?.data?.message, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
};

export const checkSellerStatus = () => async (dispatch) => {
  return axios
    .get(`/tazapay/status`)
    .then((resp) => {
      dispatch({
        type: UPDATE_TAZAPAY_STATUSES,
        payload: resp.data?.data,
      });
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const updateMobileProfilePic = (userInfoProfile) => async (dispatch) => {
  return axios
  .get(`/tazapay/status`)
  .then((resp) => {
      // console.log(resp.data?.user.profile, 'updated mobile image')
      // console.log(userInfo , 'current image')
      if(resp.data?.user.profile !== userInfoProfile ){
        // console.log("im working")   
        dispatch({
          type: PROFILE_UPDATE,
          payload: resp.data?.user,
        });
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getUenToken = () => async (dispatch) => {
  dispatch({ type: GET_UEN_TOKEN, loading: true });
  return axios
    .get(`/user/getUenToken`)
    .then((resp) => {
      if (resp.data.status === "success") {
        dispatch({
          type: GET_UEN_TOKEN,
          payload: resp.data?.data?.access_token,
        });
        return resp.data?.data;
      }
      dispatch({ type: GET_UEN_TOKEN, loading: false });
    })
    .catch((err) => {
      console.log(err.message);
      dispatch({
        type: GET_UEN_TOKEN,
        payload: "",
        loading: false,
      });
    });
};

export const signup = (data) => async (dispatch) => {
  dispatch({ type: USER_LOADING, payload: "" });
  return axios
    .post(`/user/signup`, data)
    .then((resp) => {
      // localStorage.setItem("token", resp.data.token);
      if (resp.data.token) {
        dispatch({ type: SET_USER_SIGNUP_TOKEN, payload: resp.data.token });
      }
      if (resp.data.status === "fail") {
        if (resp.data.message !== "SUCCESSFULLY_REGISTERED_USER") {
          dispatch({
            type: USER_SIGNUP,
            payload: {
              data: {
                status: "success",
                token: resp.data.token,
                data: {
                  user: resp.data.user,
                  subcription: false,
                },
              },
            },
          });

          // dispatch({
          //   type: USER_SIGNUP,
          //   error: resp.data.message,
          //   payload: data,
          // });

          localStorage.setItem(
            "SIGNUP_TEMP",
            JSON.stringify({
              type: USER_SIGNUP,
              error: resp.data.messag,
              payload: data,
            })
          );
          history.push("/signup/verify-user");
        } else {
          dispatch({
            type: USER_ALREADY_SUCCESSFULLY_REGISTERED,
            payload: {
              data: {
                status: "success",
              },
            },
          });
        }
      } else {
        console.log(JSON.stringify(resp));
        dispatch({
          type: USER_SIGNUP,
          payload: resp,
        });

        history.push("/signup/verify-user");
      }
    })
    .catch((err) => {
      // dispatch({
      //   type: USER_LOADING_CANCEL,
      //   payload: "",
      // });
      // toast.dark(err?.response?.data?.message, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    });
};

export const forgetpassword = (data, callback) => {
  return axios
    .post(`/user/forgotPassword`, data)
    .then((resp) => {
      callback(resp);
    })
    .catch((err) => {
      toast.dark(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const userResetPassword = (rtoken, data) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: "" });
  axios
    .post(`/user/resetPassword/${rtoken}`, data)
    .then((resp) => {
      dispatch({
        type: USER_RESET_PASSWORD,
        payload: resp.data,
      });

      history.push("/thank-you");
    })
    .catch((err) => {
      console.log(err.message);
      dispatch({
        type: USER_LOADING_CANCEL,
        payload: "",
      });
    });
};

export const changePassword = (data, callback) => async (dispatch) => {
  await axios
    .patch(`/user/changePassword`, data)
    .then((resp) => {
      dispatch({ type: UPDATE_USER_TOKEN, payload: resp.data.token });
      toast.dark("Your password has been changed successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
      callback(true);
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const verifyuser = () => async (dispatch) => {
  axios
    .get(`/user/fetchUser`)
    .then((resp) => {
      if (resp.data.data.isEmailVerified) {
        dispatch({
          type: USER_VERIFICATION,
          payload: resp,
        });
      }
    })
    .catch((err) => {
      toast.dark(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

let cancelToken;
export const getusercompany = (data) => async (dispatch) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  try {
    let res = await axios.get(
      `https://www.apimall-sandbox.acra.gov.sg/acra/entityQuery/entityVerification/?uen=${data}`,
      { cancelToken: cancelToken.token }
    );
    if (res.status === 200) {
      dispatch({
        type: USER_COMPANY,
        payload: res.data.name,
      });
      return res.data.name;
    }
  } catch (error) {
    dispatch({
      type: USER_COMPANY,
      payload: "",
    });
  }
};

export const dashboard = () => async () => {
  axios
    .get(`/review`)
    .then(() => {
      // console.log(resp.data.data);
    })
    .catch((err) => {
      toast.dark(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};
// export const updateuser = (data) => async (dispatch) => {
//   try {
//     let result = await axios.patch(`/user/update`, data);
//     if (result.status === 200 && result?.data?.data?.user?.name) {
//       dispatch({
//         type: USER_UPDATE,
//         payload: result.data.data,
//       });
//       dispatch({
//         type: USER_RESET,
//         payload: "",
//       });
//       localStorage.clear();
//       history.push("/login");
//     } else {
//       dispatch({
//         type: USER_UPDATE,
//         payload: result.data.data,
//       });
//       history.push("/signup/getstarted");
//     }
//   } catch (err) {
//     toast.dark(err?.response?.data?.message, {
//       position: toast.POSITION.TOP_CENTER,
//     });
//   }
// };

export const updateSignUpUser = (data) => async (dispatch, getState) => {
  const token = getState().auth.signUpinfo.token;
  try {
    let result = await axios.patch(`/user/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.status === 200 && result?.data?.data?.user?.name) {
      dispatch({
        type: USER_UPDATE,
        payload: result.data.data,
      });
      dispatch({
        type: USER_RESET,
        payload: "",
      });
      // window.location.replace("/login");
      // history.push("/login");
      history.push("/login");
      // history.push("/signup/singpass-link");
    } else {
      dispatch({
        type: USER_UPDATE,
        payload: result.data.data,
      });
      history.push("/signup/getstarted");
    }
  } catch (err) {
    toast.dark(err?.response?.data?.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const resendemail = (email) => (dispatch) => {
  axios
    .post(`/user/resendEmail`, { email })
    .then(() => {
      dispatch({
        type: USER_VERFICATION_RESEND_MAIL,
        payload: "",
      });
    })
    .catch((err) => {
      toast.dark(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const usertypeform = () => ({
  type: USER_TYPE_FORM_TOUCHED,
  payload: "",
});

export const finalform = () => ({
  type: USER_FINAL_FORM,
  payload: "",
});

export const resetCompany = () => ({
  type: RESET_COMPANY,
  payload: "",
});

export const getInterest = () => async (dispatch) => {
  axios
    .get(`/interest/getAllInterest`)
    .then((resp) => {
      // console.log(resp, "interest");
      dispatch({
        type: GET_INTEREST,
        payload: resp.data.data,
      });
    })
    .catch((err) => {
      // toast.dark(err?.response?.data?.message, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      console.log(err.message);
    });
};

export const profileEdit = (data) => async (dispatch) => {
  // dispatch({
  //   type: PROFILE_UPDATE,
  //   payload: data,
  // });
  return axios
    .patch(`/user/update`, data)
    .then((resp) => {
      dispatch({
        type: PROFILE_UPDATE,
        payload: resp.data?.data?.user,
      });
      toast.dark("Successfully updated!", { position: toast.POSITION.BOTTOM_LEFT });
    })
    .catch((err) => {
      dispatch({
        type: USER_LOADING_CANCEL,
        payload: "",
      });
      console.log(err.message);
    });
};

export const deleteAccount = (password) => async (dispatch) => {
  try {
    await axios.delete(`/user`, {
      headers: {
        password,
      },
    });

    dispatch({
      type: LOGOUT_USER,
      payload: "",
    });
    toast.dark("Deleted Account Successfully", {
      position: toast.POSITION.TOP_CENTER,
    });
  } catch (error) {
    toast.dark(error?.response?.data?.message || error.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const verifyOtp = (data) => async (dispatch, getState) => {
  let token = getState().auth?.signUpinfo?.token;
  try {
    let result = await axios.post("/user/verifyOtp", data);
    if (result.data.status === "success") {
      dispatch({ type: USER_VERIFICATION, payload: "" });
      history.push("/signup/register-profile");
    }
  } catch (err) {
    toast.dark(err?.response?.data?.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

/**
 *
 * Tazapay
 */

export const createTazapayUser = () => async (dispatch) => {
  try {
    let resp = await axios.post("/tazapay/user");
    dispatch({
      type: PROFILE_UPDATE,
      payload: resp.data?.data?.user,
    });
  } catch (err) {
    console.log(err);
    toast.dark(err?.response?.data?.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};
