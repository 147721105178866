export const OrderStatus = {
  PROCESSING: "Processing",
  ENROUTE: "Enroute",
  ONGOING_NEGOTIATIONS: "Ongoing Negotiations",
  PENDING_PAYMENT: "Pending Payment",
  PENDING_ACCEPTANCE: "Pending Acceptance",
  DELIVERED: "Delivered",
  AGREED: "Agreed",
  ACCEPTED: "Accept",
  DECLINE: "Decline",
  DISPUTE: "Dispute",
  REVIEW: "Review",
  COMPLETE: "Complete",
  ACCEPT_DISPUTE: "Accept Dispute",
  PAYOUT_COMPLETE: "Payout Completed",
  UNSUCCESSFUL: "Unsuccessful",
};

export const NotificationStatus = {
  Agreed_Offer: "Agreed Offer",
  Send_Offer: "Send Offer",
  Follow: "Follow",
  Unfollow: "Unfollow",
  Normal_Order: "Normal Order",
  Expire_Post: "Expire Post",
  Engaged_Post: "Engaged Post",
  Create_Order: "Create Order",
  Remove_Order: "Remove Order",
  Post_Taken_Off: "Post Taken Off",
  Payment_Success: "Payment Success",
  Payment_Fail: "Payment Fail",
  Message_Recived: "Message Received",
  Create_Post: 'Create Post',
};

export const MessageCount = {
  SYSTEM_MESSAGE_COUNT: "SYSTEM_MESSAGE_COUNT",
  NORMAL_MESSAGE_COUNT: "NORMAL_MESSAGE_COUNT",
};

export const SingpassKeys = {
  SINGPASS_URL: process.env.REACT_APP_SINGPASS_BASE_URL,
  CLIENT_ID: process.env.REACT_APP_SINGPASS_CLIENT_ID,
  REDIRECT_URI: process.env.REACT_APP_SINGPASS_REDIRECT_URI,
};
