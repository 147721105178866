import React, { useEffect, useState } from "react";
import { AiOutlineRedEnvelope } from "react-icons/ai";
import { BsBarChart, BsEnvelope, BsHeadphones, BsLayoutTextWindow, BsTruck } from "react-icons/bs";
import { ImCog } from "react-icons/im";
import { MdOutlineKeyboardArrowDown, MdOutlinePostAdd } from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

import ButtonComp from "../Button/ButtonComp";

import { SideBarWrapper } from "./Sidebarcompstyle";

const SideBarComp = ({ open, closeBtn, className = "" }) => {
  let history = useHistory();
  const totalUnread = useSelector((state) => state.chat.totalUnread || 0);
  const helpdeskUnread = useSelector((state) => state.chat.helpDesk?.userUnread || 0);

  const handleCreatePost = () => {
    history.push("/create-post");
  };

  return (
    <SideBarWrapper className={className}>
      <div
        className={`bar-wrap w-full pt-4 hidden md:block lg:w-64 md:w-56 ${
          open
            ? "sm:block w-96 sm:w-64 sm:fixed sm:z-50 sm:top-0 sm:left-0 bg-white sm:h-screen sm:overflow-y-scroll scrollbar-hide"
            : ""
        }`}
      >
        {closeBtn}

        {/* ---New Side Bar--- */}

        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/dashboard"
          className="nav-wrap"
        >
          <div className="icon">
            <BsBarChart />
          </div>
          <h4>Dashboard</h4>
        </NavLink>

        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/postpro-list"
          className="nav-wrap"
        >
          <div className="icon">
            <BsLayoutTextWindow />
          </div>
          <h4>Seller Marketplace</h4>
        </NavLink>

        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/buyer-forum"
          className="nav-wrap"
        >
          <div className="icon">
            <BsBarChart />
          </div>
          <h4>Buyer Forum</h4>
        </NavLink>

        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/inbox"
          className="nav-wrap"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "/inbox";
          }}
        >
          <div className="icon">
            <BsEnvelope />
          </div>
          <h4>Inbox</h4>

          {totalUnread > 0 && (
            <span
              style={{
                backgroundColor: "red",
                color: "white",
                textAlign: "center",
                borderRadius: "25px",
                width: "25px",
                height: "25px",
                marginLeft: 10,
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {totalUnread}
            </span>
          )}
        </NavLink>

        <section className="py-1 ">
          <div className="flex items-center gap-2">
            <p className="pl-6 text-gray-400 ">Create</p>
            <span className="w-full h-px border-b border-dashed border-gray-400  "></span>
          </div>
          <NavLink
            activeStyle={{ color: "#8B0000" }}
            style={{ textDecoration: "none" }}
            to="/create-post"
            className="nav-wrap"
          >
            <div className="icon">
              <BsTruck />
            </div>
            <h4>Sale/Lease Post</h4>
          </NavLink>

          <NavLink
            activeStyle={{ color: "#8B0000" }}
            style={{ textDecoration: "none" }}
            to="/buyer-request/create"
            className="nav-wrap"
          >
            <div className="icon">
              <MdOutlinePostAdd />
            </div>
            <h4>Buyer Request</h4>
          </NavLink>
        </section>

        <section className="py-1 ">
          <div className="flex items-center gap-2">
            <p className="pl-6 text-gray-400 flex-shrink-0">View My Items</p>
            <span className="w-full h-px border-b border-dashed border-gray-400  "></span>
          </div>
          <NavLink
            activeStyle={{ color: "#8B0000" }}
            style={{ textDecoration: "none" }}
            to="/mypost"
            className="nav-wrap"
          >
            <div className="icon">
              <MdOutlinePostAdd />
            </div>
            <h4>Post</h4>
          </NavLink>

          <NavLink
            activeStyle={{ color: "#8B0000" }}
            style={{ textDecoration: "none" }}
            to="/mydraft"
            className="nav-wrap"
          >
            <div className="icon">
              <AiOutlineRedEnvelope />
            </div>
            <h4>Draft</h4>
          </NavLink>

          <NavLink
            activeStyle={{ color: "#8B0000" }}
            style={{ textDecoration: "none" }}
            to="/buyer-request/my-request"
            className="nav-wrap"
          >
            <div className="icon">
              <BsTruck />
            </div>
            <h4>Request</h4>
          </NavLink>
        </section>

        <section className="py-1 ">
          <div className="flex items-center gap-2">
            <p className="pl-6 text-gray-400 flex-shrink-0">Orders</p>
            <span className="w-full h-px border-b border-dashed border-gray-400  "></span>
          </div>
          <NavLink
            activeStyle={{ color: "#8B0000" }}
            style={{ textDecoration: "none" }}
            to="/orders/cockpits/myorder"
            className="nav-wrap"
          >
            <div className="icon">
              <img src="/images/pending-transaction.png" alt="Icon" className="w-5" />
            </div>
            <h4>Pending Transactions</h4>
          </NavLink>

          <NavLink
            activeStyle={{ color: "#8B0000" }}
            style={{ textDecoration: "none" }}
            to="/orders/complete/myorder"
            className="nav-wrap"
          >
            <div className="icon">
              <img src="/images/completed-orders.png" alt="Icon" className="w-5" />
            </div>
            <h4>Completed Orders</h4>
          </NavLink>

          <NavLink
            activeStyle={{ color: "#8B0000" }}
            style={{ textDecoration: "none" }}
            to="/orders/unsuccessful/myorder"
            className="nav-wrap"
          >
            <div className="icon">
              <img src="/images/unsuccessful-orders.png" alt="Icon" className="w-5" />
            </div>
            <h4>Unsuccessful Orders</h4>
          </NavLink>
          <div className="flex items-center gap-2">
            <span className="w-full h-px border-b border-dashed border-gray-400  "></span>
          </div>
        </section>

        {/* <section className="py-1 border-b border-b-gray-200 border-dashed">
          <div className="flex items-center gap-2">
            <p className="pl-6 text-gray-400 ">Orders</p>
            <span className="w-full h-px border-b border-dashed border-gray-400  "></span>
          </div>


          <NestedNavLink
            title="Pending Transactions"
            linkTo="/orders/cockpits"
            iconLeft={<img src="/images/pending-transaction.png" alt="Icon" className="w-5" />}
            children={[
              {
                title: "My Transactions",
                linkTo: "/myorder",
                iconLeft: <BsTruck />,
              },
              // {
              //   title: "Coworker",
              //   linkTo: "/coworker",
              //   iconLeft: <BsTruck />,
              // },
            ]}
          />

          <NestedNavLink
            title="Completed"
            linkTo="/orders/complete"
            iconLeft={<img src="/images/completed-orders.png" alt="Icon" className="w-5" />}
            children={[
              {
                title: "My Orders",
                linkTo: "/myorder",
                iconLeft: <BsTruck />,
              },
              // {
              //   title: "Coworker Orders",
              //   linkTo: "/coworker",
              //   iconLeft: <BsTruck />,
              // },
            ]}
          />

          <NestedNavLink
            title="Unsuccessful "
            linkTo="/orders/unsuccessful"
            iconLeft={<img src="/images/unsuccessful-orders.png" alt="Icon" className="w-5" />}
            children={[
              {
                title: "My Orders",
                linkTo: "/myorder",
                iconLeft: <BsTruck />,
              },
              // {
              //   title: "Coworker Orders",
              //   linkTo: "/coworker",
              //   iconLeft: <BsTruck />,
              // },
            ]}
          />
        </section> */}

        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/following"
          className="nav-wrap"
        >
          <div className="icon">
            <BsTruck />
          </div>
          <h4>User Follow</h4>
        </NavLink>

        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/reviews"
          className="nav-wrap"
        >
          <div className="icon">
            <img src="/images/review.png" alt="Icon" className="w-5" />
          </div>
          <h4>Reviews</h4>
        </NavLink>

        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/performance"
          className="nav-wrap"
        >
          <div className="icon">
            <img src="/images/performance.png" alt="Icon" className="w-5" />
          </div>
          <h4>Performance</h4>
        </NavLink>
        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/help-desk"
          className="nav-wrap"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "/help-desk";
          }}
        >
          <div className="icon">
            <BsHeadphones />
          </div>
          <h4>Help Desk</h4>
          {helpdeskUnread > 0 && (
            <span
              style={{
                backgroundColor: "red",
                color: "white",
                textAlign: "center",
                borderRadius: "25px",
                width: "25px",
                height: "25px",
                marginLeft: 10,
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {helpdeskUnread}
            </span>
          )}
        </NavLink>

        {/* ---Old side bar */}
        {/* <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/dashboard"
          className="nav-wrap"
        >
          <div className="icon">
            <BsBarChart />
          </div>
          <h4>Dashboard</h4>
        </NavLink>

         <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/postpro-list"
          className="nav-wrap"
        >
          <div className="icon">
            <BsLayoutTextWindow />
          </div>
          <h4>Seller Marketplace</h4>
        </NavLink>


        <section className="py-1 border-b border-b-gray-200 border-dashed">
          <div className="flex items-center gap-2">
            <p className="pl-6 text-gray-400 ">Orders</p>
            <span className="w-full h-px border-b border-dashed border-gray-400  "></span>
          </div>

          <NestedNavLink
            title="Pending Transactions"
            linkTo="/orders/cockpits"
            iconLeft={<img src="/images/pending-transaction.png" alt="Icon" className="w-5" />}
            children={[
              {
                title: "My Transactions",
                linkTo: "/myorder",
                iconLeft: <BsTruck />,
              },
              {
                title: "Coworker",
                linkTo: "/coworker",
                iconLeft: <BsTruck />,
              },
            ]}
          />

          <NestedNavLink
            title="Completed"
            linkTo="/orders/complete"
            iconLeft={<img src="/images/completed-orders.png" alt="Icon" className="w-5" />}
            children={[
              {
                title: "My Orders",
                linkTo: "/myorder",
                iconLeft: <BsTruck />,
              },
              {
                title: "Coworker Orders",
                linkTo: "/coworker",
                iconLeft: <BsTruck />,
              },
            ]}
          />

          <NestedNavLink
            title="Unsuccessful "
            linkTo="/orders/unsuccessful"
            iconLeft={<img src="/images/unsuccessful-orders.png" alt="Icon" className="w-5" />}
            children={[
              {
                title: "My Orders",
                linkTo: "/myorder",
                iconLeft: <BsTruck />,
              },
              {
                title: "Coworker Orders",
                linkTo: "/coworker",
                iconLeft: <BsTruck />,
              },
            ]}
          />
        </section>

        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/following"
          className="nav-wrap"
        >
          <div className="icon">
            <BsTruck />
          </div>
          <h4>Following</h4>
        </NavLink>

        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/inbox"
          className="nav-wrap"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "/inbox";
          }}
        >
          <div className="icon">
            <BsEnvelope />
          </div>
          <h4>Inbox</h4>

          {totalUnread > 0 && (
            <span
              style={{
                backgroundColor: "red",
                color: "white",
                textAlign: "center",
                borderRadius: "25px",
                width: "25px",
                height: "25px",
                marginLeft: 10,
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {totalUnread}
            </span>
          )}
        </NavLink>
        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/reviews"
          className="nav-wrap"
        >
          <div className="icon">
            <img src="/images/review.png" alt="Icon" className="w-5" />
          </div>
          <h4>Reviews</h4>
        </NavLink>
        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/mypost"
          className="nav-wrap"
        >
          <div className="icon">
            <MdOutlinePostAdd />
          </div>
          <h4>My Posts</h4>
        </NavLink>
        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/mydraft"
          className="nav-wrap"
        >
          <div className="icon">
            <AiOutlineRedEnvelope />
          </div>
          <h4>Draft</h4>
        </NavLink>

        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/performance"
          className="nav-wrap"
        >
          <div className="icon">
            <img src="/images/performance.png" alt="Icon" className="w-5" />
          </div>
          <h4>Performance</h4>
        </NavLink>
        <NavLink
          activeStyle={{ color: "#8B0000" }}
          style={{ textDecoration: "none" }}
          to="/help-desk"
          className="nav-wrap"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "/help-desk";
          }}
        >
          <div className="icon">
            <BsHeadphones />
          </div>
          <h4>Help Desk</h4>
          {helpdeskUnread > 0 && (
            <span
              style={{
                backgroundColor: "red",
                color: "white",
                textAlign: "center",
                borderRadius: "25px",
                width: "25px",
                height: "25px",
                marginLeft: 10,
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {helpdeskUnread}
            </span>
          )}
        </NavLink> */}
        {/* <div className="bar-btn">
          <ButtonComp
            name="CREATE POST"
            width="11rem"
            height="2.3rem"
            border="1px solid white"
            fontsize="0.9rem"
            margin="0 auto"
            onClick={handleCreatePost}
          />
        </div> */}
      </div>
    </SideBarWrapper>
  );
};

export default React.memo(SideBarComp);

const NestedNavLink = ({ title = "", iconLeft, linkTo, children = [], whitelisted = null }) => {
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(linkTo) || location.pathname?.includes(whitelisted)) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [linkTo, location.pathname]);

  return (
    <div>
      <NavLink
        activeStyle={{ color: "#8B0000" }}
        style={{ ...(show ? { color: "#8B0000" } : {}) }}
        to={linkTo}
        className="nav-wrap active cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          setShow(!show);
        }}
      >
        <div className="icon">{iconLeft}</div>
        <h4>{title}</h4>
        <div className={`text-2xl right duration-500 transition-all ${show ? "rotate-180" : ""}`}>
          <MdOutlineKeyboardArrowDown />
        </div>
      </NavLink>
      <div
        className={`px-2 overflow-hidden bg-slate-100  duration-800 transition-all ${
          show ? "max-h-screen" : "max-h-0"
        }`}
      >
        {children.map((c) => (
          <NavLink
            key={c.linkTo}
            activeStyle={{ color: "#8B0000" }}
            style={{ textDecoration: "none" }}
            to={linkTo + c.linkTo}
            className="nav-wrap"
          >
            <div className="icon">{c.iconLeft}</div>
            <h4>{c.title}</h4>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
