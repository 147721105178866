import { toast } from "react-toastify";
import axios from "axios";

import { history } from "../../routes";
import { formatChat } from "../../utils/format";
import {
  CANCEL_AGREE_MODAL,
  CREATE_NORMAL_CONVERSATION,
  GET_ADMIN_CONVERSATION,
  GET_ALL_CONVERSATION,
  GET_CONVERSATION,
  OFFER_AGREE,
} from "../ActionTypes";

/**
 *
 * @param {{ order_id: String, chat_id: String, post_id: String, chat: any}} data
 * @param { {(success: any, error: Error, ) => void } } callback
 * @returns
 */
export const getConversation = (data, callback = () => null) => {
  return (dispatch) => {
    if (data?.chat) {
      dispatch({
        type: GET_CONVERSATION,
        payload: formatChat(data?.chat),
      });
      data.chat_id = data?.chat?._id;
    }

    return axios
      .get(`/chat/getConversation`, {
        params: {
          seller_id: data?.seller_id,
          buyer_id: data?.buyer_id,
          post_id: data?.post_id,
          chat_id: data?.chat_id,
          order_id: data?.order_id,
        },
      })
      .then((resp) => {
        dispatch({
          type: GET_CONVERSATION,
          payload: resp.data.data.objChat,
        });
        callback(resp.data.data.objChat);
      })
      .catch((err) => {
        console.log(err?.response?.data || err);
        callback(null, err?.response?.data || err);
      });
  };
};

export const getAllConversation = () => (dispatch) => {
  axios
    .get(`/chat/allConversationList`)
    .then((resp) => {
      dispatch({
        type: GET_ALL_CONVERSATION,
        payload: resp.data.data,
      });
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const resetConversation = () => (dispatch) => {
  dispatch({
    type: GET_CONVERSATION,
    payload: null,
  });
};

export const getNormalConversationByPostId = ({ post_id }, callback = () => null) => {
  return (dispatch) => {
    return axios
      .get(`/chat/getNormalConversationByPostId/${post_id}`)
      .then((resp) => {
        // console.log(resp);
        dispatch({
          type: GET_CONVERSATION,
          payload: resp.data.data.objChat,
        });
        callback(resp);
      })
      .catch((err) => {
        console.log(err.message);
        callback(null, err);
      });
  };
};

export const getConversationByChatId = ({ chat_id }, callback = () => null) => {
  return (dispatch) => {
    return axios
      .post(`/chat/getConversationByChatId/${chat_id}`)
      .then((resp) => {
        // console.log(resp);
        dispatch({
          type: GET_CONVERSATION,
          payload: resp.data.data.objChat,
        });
        callback(resp);
      })
      .catch((err) => {
        console.log(err.message);
        callback(null, err);
      });
  };
};

/**
 *
 * @param {{ conversation_id: String, post_id: String}} data
 * @returns
 */
export const offerAgree = (data) => (dispatch) => {
  return axios
    .post(`/chat/offerAgreePoster`, data)
    .then(() => {
      console.log("AGREED");
      dispatch({
        type: OFFER_AGREE,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err.message);
      toast.dark(err?.response?.data?.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
};

export const cancelAgreeModal = () => ({
  type: CANCEL_AGREE_MODAL,
  payload: "",
});

export const createNormalConversation = (data) => (dispatch) => {
  return axios
    .post(`/chat/normalConversation`, data)
    .then((resp) => {
      // console.log(resp, "Normal chat");
      dispatch({
        type: CREATE_NORMAL_CONVERSATION,
        payload: resp.data.data?.objChat,
      });
      history.push("/inbox");
    })
    .catch((err) => {
      console.log(err.message);
      toast.dark(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const updateHelpDeskChat = (data) => (dispatch) => {
  dispatch({
    type: GET_ADMIN_CONVERSATION,
    payload: data?.chats,
  });
};

// HELP DESK
export const getHelpDeskConversation = () => (dispatch, getState) => {
  return axios
    .get(`/help_desk`, { params: { user: getState().auth.user._id } })
    .then((resp) => {
      dispatch(updateHelpDeskChat(resp.data?.data));
    })
    .catch((err) => {
      console.log(err.message);
    });
};

//set msg to seen
export const setAdminMsgSeen = () => (dispatch, getState) => {
  return axios
    .get(`/help_desk/set-as-seen`, { params: { user: getState().auth.user._id } })
    .then((resp) => {
      console.log(resp.data);
      // dispatch(updateHelpDeskChat(resp.data?.data));
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const createHelpDeskConversation = (data) => (dispatch) => {
  return axios
    .post(`/help_desk`, data)
    .then((resp) => {
      dispatch(updateHelpDeskChat(resp.data?.data));
    })
    .catch((err) => {
      console.log(err.message);
    });
};
