import React, { useEffect, useRef, useState } from "react";
import { BsBell, BsChevronDown, BsEnvelope } from "react-icons/bs";
import { IoIosMenu } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { isMobileDevice } from "react-select/dist/index-ea9e225d.cjs.prod";
import { debounce } from "lodash";

import NotificationPage from "../../pages/NotificationPage/NotificationPage";
import { checkSellerStatus } from "../../redux/actions/AuthActions";
import { getAllConversation, getHelpDeskConversation, updateHelpDeskChat } from "../../redux/actions/ChatAction";
import { addNotification, getNotification } from "../../redux/actions/NotificationAction";
import { LOGOUT_USER } from "../../redux/ActionTypes";
import { MessageCount } from "../../utils/constants";
import { chatUnreadCount, initSocket, subscribeNotification, subscribeToHelpDesk } from "../../utils/socket";
import ButtonComp from "../Button/ButtonComp";

import { HeaderWrapper } from "./Headerstyles";

const { REACT_APP_ENVIRONMENT } = process.env;

function HeaderComp({ onClickBurger, ...props }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationRef = useRef();
  const location = history.location;

  const { unseen, hasMore } = useSelector((state) => state.notification);
  const { user, token } = useSelector((state) => state.auth);
  const totalUnreadMsg = useSelector((state) => state.chat.totalUnread);

  const [dropdown, setDropdown] = useState(null); // enum -> notification | profile
  const [page, setPage] = useState(1);
  const [limit] = useState(50);

  const userId = user?._id;

  const handleLogout = () => {
    dispatch({
      type: LOGOUT_USER,
    });
  };

  function getEnv() {
    return {
      isDev: REACT_APP_ENVIRONMENT === "development",
      isStage: REACT_APP_ENVIRONMENT === "staging",
      isProd: REACT_APP_ENVIRONMENT === "production",
    };
  }
  // Notification and User dropdown hide onclick outside
  useEffect(() => {
    window.onclick = (e) => {
      if (dropdown) {
        if (!notificationRef?.current?.contains(e.target)) {
          setDropdown(null);
        }
      }
    };
  });

  useEffect(() => {
    setDropdown(null);
  }, [location]);

  const debounceNotification = debounce(() => {
    dispatch(getNotification({ page, limit }));
  }, 1000);

  useEffect(() => {
    if (token) {
      debounceNotification();
    }
  }, [page, token]);

  useEffect(() => {
    if (dropdown === "notification") {
      debounceNotification();
    }
  }, [dropdown]);

  useEffect(() => {
    if (token) {
      dispatch(checkSellerStatus());
    }
  }, [token]);

  useEffect(() => {
    const client = notificationRef?.current;
    if (client) {
      client.onscroll = (e) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 5) {
          if (hasMore) setPage(page + 1);
        }
      };
    }
  });

  useEffect(() => {
    if (userId) {
      initSocket(`USER_${String(user._id)}`);

      chatUnreadCount(() => {
        dispatch(getAllConversation());
      });

      subscribeNotification((data) => {
        if (data) {
          dispatch(addNotification(data));
        }
      });

      subscribeToHelpDesk((chat) => {
        dispatch(updateHelpDeskChat(chat));
      });

      dispatch(getAllConversation());
      dispatch(getHelpDeskConversation());
    }
  }, [userId]);

  return (
    <HeaderWrapper props={props}>
      <div
        className={`container-head w-full nav ${
          getEnv().isDev ? "bg-green-500" : getEnv().isStage ? "bg-sky-600" : "bg-primary"
        }`}
      >
        <div className="flex justify-start">
          {token && (
            <IoIosMenu onClick={onClickBurger} color="white" size={40} className="mr-3 cursor-pointer md:hidden" />
          )}
          <a className="logo-wrapper" href="https://construcshare.com">
            <img
              src="/icons/logo-white.svg"
              alt="logo"
              onClick={() => {
                history.push("/dashboard");
              }}
            />

            <span className="title hidden md:block">
              construc<b>share</b>
            </span>
          </a>
        </div>
        {props.mainheader ? (
          <div className="feature-wrapper flex">
            {/* <div className="input lg:w-80 md:w-60  hidden md:block" onClick={() => history.push("/search")}>
              <InputComp
                icon="/icons/search.svg"
                placeholder="Search transactions, invoices or help"
                // width="340px"
                height="35px"
                borderradius="0.5rem"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onFocus={() => history.push("/search")}
                autoFocus={props.autoFocus}
              />
            </div> */}

            <div
              className="bell-icon flex gap-2 items-center"
              onClick={() => {
                history.push("/inbox");
              }}
            >
              <BsEnvelope />
              {Number(totalUnreadMsg) !== 0 && (
                <>
                  <span className="-mt-0.5">|</span>
                  <span>{totalUnreadMsg}</span>
                </>
              )}
            </div>

            <div
              className="bell-icon flex gap-2 items-center"
              onClick={() => {
                // history.push("/notification");
                setDropdown((dropdown) => (dropdown === "notification" ? null : "notification"));
              }}
            >
              <BsBell />
              {unseen > 0 && (
                <>
                  <span className="-mt-0.5">|</span>
                  <span>{unseen >= 100 ? "99+" : unseen}</span>
                </>
              )}
            </div>
            {dropdown === "notification" && (
              <div className="absolute top-20 right-14 h-2/3 lg:w-1/3 md:w-1/2 sm:w-full sm:right-0 sm:h-full ">
                <div className="w-5 h-5 bg-gray-100 absolute right-28 -top-2 border-t border-l rotate-45"></div>
                <div
                  id="notification-container"
                  ref={notificationRef}
                  className="absolute top-0 right-0 bg-gray-100 p-5 overflow-y-scroll h-full w-full border-b border-l border-r z-20 rounded-2xl shadow-2xl"
                >
                  <NotificationPage />
                </div>
              </div>
            )}
            <div className="vl hidden md:block"></div>

            <div
              className="profile "
              onClick={() => {
                setDropdown((dropdown) => (dropdown === "profile" ? null : "profile"));
              }}
            >
              <img
                src={user?.profile ? user?.profile : "../images/avtar.png"}
                alt="profile"
                onError={(e) => (e.target.src = "/images/avtar.png")}
              />
              <div className="profile-info hidden md:block">
                <div className="profile-info-msg">Hello</div>
                <div className="profile-info-name">{user?.name || "****"} </div>
              </div>
            </div>

            <div
              className="arrow-btn dropdown mt-2 sm:mt-0   z-20"
              onClick={() => {
                setDropdown((dropdown) => (dropdown === "profile" ? null : "profile"));
              }}
            >
              <BsChevronDown className="hidden md:block" />
              {dropdown === "profile" && (
                <div className="dropdown-content w-44">
                  <Link to="/profile-edit">Profile</Link>
                  <Link to="/change-password">Change Password</Link>
                  <h5 onClick={handleLogout}>Logout</h5>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="feature-buttons flex flex-row gap-8 sm:gap-4 items-center">
            {/* <div className="feature-link">How it Works ?</div> */}
            {/* <div className="feature-link sm:text-xs">Help Desk</div> */}
            {location.pathname === "/signup" ? (
              <ButtonComp
                name="Login"
                width="8rem"
                height="2.5rem"
                border="1px solid white"
                bg="transparent"
                margin="0"
                onClick={() => {
                  history.push("/login");
                }}
              />
            ) : location.pathname === "/login" ? (
              <ButtonComp
                name="Register"
                width="8rem"
                height="2.5rem"
                border="1px solid white"
                bg="transparent"
                margin="0"
                onClick={() => {
                  history.push("/signup");
                }}
              />
            ) : (
              <>
                <ButtonComp
                  name="Login"
                  width="8rem"
                  height="2.5rem"
                  border="1px solid white"
                  bg="transparent"
                  margin="0"
                  onClick={() => {
                    history.push("/login");
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>
    </HeaderWrapper>
  );
}

export default HeaderComp;
